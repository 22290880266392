import * as currency_js from 'currency.js';

import codes from './codes.json';
import currencies from './currencies.json';

const currencyData = currencies as {
  [code: string]: {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
  };
};

const currency = (value: string, currencyCode = 'USD') => {
  const groups = value.match(/^([A-Z]{3})-?[0-9]+(\.[0-9]*)?$/);
  const symbol = (groups && groups[1]) || currencyCode;

  const curr = currencyData[symbol];
  const precision = (curr && curr.decimal_digits) || 0;

  return currency_js.default(value, {
    symbol,
    formatWithSymbol: true,
    precision,
    separator: '',
  });
};

export { currency };

const countryToCurrencyCodes = codes as {
  [countryCode: string]: string;
};

export const getCurrencyCodeFromCountryCode = (countryCode: string) => {
  return countryToCurrencyCodes[countryCode] || '';
};
